html{
  background-color: #f1f0ec;
  overflow-x: hidden;
}

*{
  outline: none;
}

a{
  color: black;
  text-decoration: none;
}

/*.footer .footer_flex a:hover{
  color: white;
  background-color: #202020;
}




.footer .footer_socials a:hover{
  color: white;
  background-color: #202020;
}

.footer_mailchimp #mc-embedded-subscribe:hover{
  color: white;
  background-color: #202020;
}*/


.footer .footer_flex a span:hover{
  color: #125ABB;
}
/*.scrollable-content::-webkit-scrollbar * {
    background:transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background:#999 !important;
}*/

.footer a:hover{
  color: #125ABB;
}

.button:hover{
  color: #125ABB;
}


*::-moz-selection {
  background-color: #202020;
  color: #f1f0ec;
}


*::selection {
  background-color: #202020;
  color: #f1f0ec;
}

body{
  /*background-color: #f1f0ec;*/
  /*overflow-x: hidden;*/
  background-color: transparent;
}


.img_source_mobile{
  display: none;
}

marquee{
  font-size: 4vw;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  letter-spacing: 0.5vw;
}

marquee span{
  font-family: "Times New Roman", Times, serif;
}

label input{
  border: none;
  background-color: #f1f0ec;
  border-bottom: 1px solid;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  font-size: 1vw;

}

input{
  outline: none;
  border: none;
  padding: 0;
  background-color: #f1f0ec;
  margin-top: 0.5vw;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  font-size: 1vw;
  cursor: pointer;
}

textarea, input {
  outline: none;
 }




/*specific colours for marquees*/

.section_3 marquee{
  color: #AD1C0F;
  /*margin-top: 3vw;*/
}

.section_4 marquee{
  color: #125ABB;
}

.section_5 marquee{
  color: #025634;
}

.App{
  position: relative;
  margin-bottom: 3vw;
}

.section_1{
  width: 100vw;
  position: relative;
}

.section_1 .mobile{
  display: none;
}

/* .section_1 .slick-slide div img:last-child{
  display: none;
} */

.section_1 .slick-slide img {
    display: block;
    width: 100vw;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}


.slick-slide { -webkit-transform: translate3d(0,0,0); }


.slide1,.slide2,.slide3,.slide4,.slide5 {
  position: absolute;
}


.section_1 header{
  position: absolute;
  top: 0;
  width: 100vw;
  height: 14vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  z-index: 1;

}


.section_1 header img{
  height: 52%;
  margin-left: 2vw;
}

/*.section_1 .slider_container img{
    width: 100vw;

}*/

.section_1 footer{
  width: 100vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: 10vh;
}


.section_2{
  width: 100vw;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;

}


.section_2 header{
  width: 100vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 1.5vw;
      margin-top: 1vw;
}



.section_2 header .menu_container{

display: -ms-flexbox;

display: flex;
-ms-flex-align: center;
    align-items: center;
position: relative;

}


.section_2 header .menu_container div{
  position: relative;
  margin-left: 1vw;
}

.section_2 header .menu_container div:nth-child(2) img:first-child{
      width: 14vw;
}

.section_2 header .menu_container div img:first-child{
      width: 17vw;
}

.section_2 header .menu_container div img:last-child{
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      visibility: hidden;
}

.section_2 header .menu_container div:first-child{
  margin-left: 0;
}

.section_2 header .menu_container div{
  margin-left: 1vw;
}

.section_2 header .menu_container div img:first-child:hover{
  cursor: pointer;
}

.section_2 header .menu_container div img:first-child:hover  + img:last-child{
  visibility: visible;
}




.section_2 .heading_container{
  width: 94.1vw;
}


.section_2 .heading_container h1{
    margin: 0;
    font-size: 7.2vw;
    font-family: "Times New Roman", Times, serif;
    font-weight: lighter;
    line-height: 114%;
    letter-spacing: 0.1vw;
}


.section_2 .text_container{
    width: 94.1vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    margin-top: 5vw;
    margin-bottom: 0;
}


.section_2 .text_container .first_col{
  width: 50%;
}



.section_2 .text_container .first_col .slider img{
    height: 25.5vw;

}


.section_2  .slide1 {
 animation:rotate_3imgs 8s infinite;
-webkit-animation:rotate_3imgs 8s infinite;

}
.section_2  .slide2 {
    animation:rotate_3imgs_2 8s infinite;
-webkit-animation:rotate_3imgs_2 8s infinite;
}
.section_2  .slide3 {
 animation:rotate_3imgs_3 8s infinite;
-webkit-animation:rotate_3imgs_3 8s infinite;
}

.section_2  .slide4 {
  animation:rotate_3imgs_4 8s infinite;
 -webkit-animation:rotate_3imgs_4 8s infinite;
 }

.section_2 .text_container .second_col{
  width: 50%;
}


.section_2 .text_container .second_col p{
  font-size: 3.1vw;
  line-height: 123%;
  margin: 0;
  font-family: "Times New Roman", Times, serif;
}

.section_3{
  width: 100vw;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.section_3 header{
  width: 100vw;
  height: 20vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}


.section_3 .text_container{
    width: 94.1vw;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 3vw;
    margin-top: 0.5vw;
}


.section_3 .text_container .first_col{
  width: 50%;
  margin-top: 4.6vw;
}


.section_3 .text_container .second_col{
  width: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: left;
      align-items: left;
}


/*Margin 8vw?*/
.section_3 .text_container .first_col p{
  font-size: 1.5vw;
  line-height: 150%;
  margin: 0;
  margin-right: 8vw;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
}

.section_3 .text_container .second_col img{
    width: 94%;
}

.section_3 .text_container .second_col .slide1 {
 animation:rotate_4imgs 10s infinite;
-webkit-animation:rotate_4imgs 10s infinite;

}
.section_3 .text_container .second_col .slide2 {
    animation:rotate_4imgs_2 10s infinite;
-webkit-animation:rotate_4imgs_2 10s infinite;
}
.section_3 .text_container .second_col .slide3 {
 animation:rotate_4imgs_3 10s infinite;
-webkit-animation:rotate_4imgs_3 10s infinite;
}

.section_3 .text_container .second_col .slide4 {
 animation:rotate_4imgs_4 10s infinite;
-webkit-animation:rotate_4imgs_4 10s infinite;
}



.section_3 .third_grid_container{
  width: 94.1vw;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6vw;
  padding-bottom: 15vw;
}

.section_3 .third_grid_container .mob_only{
  display: none;
}


.section_3 .third_grid_container .first_col{
  width: 33.33%;
}


.section_3 .first_col .slide1 {
 animation:rotate_3imgs 10s infinite;
-webkit-animation:rotate_3imgs 10s infinite;

}
.section_3  .first_col .slide2 {
    animation:rotate_3imgs_2 10s infinite;
-webkit-animation:rotate_3imgs_2 10s infinite;
}
.section_3  .first_col .slide3 {
 animation:rotate_3imgs_3 10s infinite;
-webkit-animation:rotate_3imgs_3 10s infinite;
}

.section_3  .first_col .slide3 {
  animation:rotate_3imgs_3 10s infinite;
 -webkit-animation:rotate_3imgs_3 10s infinite;
 }



.section_3 .third_grid_container .first_col img{
  width: 24vw;
}

.section_3 .third_grid_container .second_col{
  width: 31.33%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: left;
      justify-content: left;
  margin-top: 8vw;

}

.section_3 .third_grid_container .second_col img{
  width: 23.4vw;
}


.section_3 .second_col .slide1 {
 animation:rotate_3imgs 13s infinite;
-webkit-animation:rotate_3imgs 13s infinite;

}
.section_3  .second_col .slide2 {
    animation:rotate_3imgs_2 13s infinite;
-webkit-animation:rotate_3imgs_2 13s infinite;
}
.section_3  .second_col .slide3 {
 animation:rotate_3imgs_3 13s infinite;
-webkit-animation:rotate_3imgs_3 13s infinite;
}

.section_3  .second_col .slide4 {
  animation:rotate_3imgs_4 13s infinite;
 -webkit-animation:rotate_3imgs_4 13s infinite;
 }


.section_3 .third_grid_container .third_col{
  width: 31.33%;
  margin-top: 44.5vh;
  margin-left: 2vw;

}

.section_3 .third_grid_container .third_col p{

    margin: 0;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-size: 1.55vw;
    line-height: 150%;
}


.section_3 footer{

  width: 100vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
    margin-top: 5vw;

}


.section_3 footer img{
  width: 100vw;
}

.section_4{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100vw;
  position: relative;
  margin-bottom: 6vw;


}

.section_4 header{

    width: 100vw;
    height: 20vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;

}

.section_4 .third_grid_container{
  width: 83vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: -2vw;
}

.section_4 .third_grid_container .section_4_mob{
    display: none;
}


.section_4_mobile{
  display: none;
}


.section_4 .third_grid_container .first_col,
.section_4 .third_grid_container .second_col,
.section_4 .third_grid_container .third_col {
  width: 33.33%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;

}


.section_4 .third_grid_container a img{
  width: 100%;
  -webkit-filter: brightness(79%);
          filter: brightness(79%);
}


.section_4 .third_grid_container a img:hover{
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  cursor: pointer;
  }

  .section_4 .third_grid_container_mobile{
    display: none;
  }



.section_4 footer{
  margin-top: 3.7vw;
  width: 38vw;
  height: 16vw;
}


.section_4 footer p{
  margin: 0;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  font-size: 1.5vw;
  line-height: 150%;


}

.section_4 footer p:first-child{
  margin-bottom: 2.5vw;
}


.section_4 footer .menu_vertical{
  position: absolute;
  left: 2.9vw;
  margin: 0;
  -webkit-transform-origin: left bottom;
      -ms-transform-origin: left bottom;
          transform-origin: left bottom;
-webkit-transform: rotate(-90deg) translateY(100%) translateX(-90%);
    -ms-transform: rotate(-90deg) translateY(100%) translateX(-90%);
        transform: rotate(-90deg) translateY(100%) translateX(-90%);
}

.section_4 footer .menu_vertical p{
    font-family: "Times New Roman", Times, serif;
    font-size: 2.5vw;
}


/*Weird stuff?*/
/*.section_4 footer p:last-child{
  margin-bottom: 2.5vw;
}*/


.section_5{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
}


.section_5 header{

    width: 100vw;
    height: 20vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;

}



.section_5 .text_container{
    width: 94.1vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
}



.section_5 .text_container .first_col{
  width: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: left;
      align-items: left;
  -ms-flex-direction: column;
      flex-direction: column;

}

.section_5 .second .first_col{
  margin-top: 13vw;
}

.section_5 .text_container .second_col{
  width: 50%;
}


.section_5 .text_container .second_col h1{
  font-family: Times New Roman,Times,serif;
  font-weight: lighter;
  font-size: 3.9vw;
  margin-top: 1.3vw;
  line-height: 120%;
  margin-bottom: 2.4vw;
}

.section_5 .text_container .first_col p{

  margin: 0;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  font-size: 1.6vw;
  line-height: 150%;
  margin-bottom: 2.3vw;
  padding-right: 6.7vw;

}




.section_5 .text_container .second_col img{
    width: 110%;
    margin-top: 1.2vw;
}



.section_5 .text_container .first_col img{
  width: 24vw;
  margin-bottom: 5vw;
}

.section_5 .text_container:last-child .second_col p{
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  font-size: 1.6vw;
  line-height: 150%;
  margin-bottom: 2vw;
  padding-right: 8vw;

}

.section_5 .text_container:last-child .second_col p:first-child{
  margin: 0;
}

.section_5  .slide1 {
 animation:rotate_3imgs 8s infinite;
-webkit-animation:rotate_3imgs 8s infinite;

}
.section_5  .slide2 {
    animation:rotate_3imgs_2 8s infinite;
-webkit-animation:rotate_3imgs_2 8s infinite;
}
.section_5  .slide3 {
 animation:rotate_3imgs_3 8s infinite;
-webkit-animation:rotate_3imgs_3 8s infinite;
}


.section5_mobile{
  display: none;
}


.footer{
  width: 100vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding-bottom: 4vw;
  margin-top: 4.2vw;
}


.footer .text_container{
  width: 90vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
}


.footer .text_container .first_col{
  width: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: left;
      justify-content: left;
}

.footer .text_container .first_col .first_col_container{
  width: 66%;
  margin-left: -4vw;
}


.footer .text_container .first_col .first_col_container .footer_title{
    margin-bottom: 1.3vw;
}

.footer .text_container .first_col .first_col_container .footer_flex{
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.3vw;
}

.footer .text_container .first_col .first_col_container .footer_flex a{
  margin-left: 1.6vw;
}


.footer .text_container .first_col a{
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
}

.footer .text_container p,
.footer .text_container a
{
  margin: 0;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  font-size: 1.2vw;

}

.footer .text_container .second_col .second_col_container .footer_title{
    margin-bottom: 1.4vw;
}

.footer .text_container .second_col p{
  margin: 0;
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;

}


.footer .text_container .second_col a{
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
}

.footer .text_container .second_col{
  width: 50%;
}


.footer .text_container .second_col .second_col_container .footer_mailchimp .mailchimp_col_container .flex{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 1.8vw;
}

.footer .text_container .second_col .second_col_container .footer_mailchimp .mailchimp_col_container .flex label input{
    width: 100%;
}

.footer .text_container .second_col .second_col_container .footer_mailchimp .mailchimp_col_container .flex div:first-child{
  width: 16%;
}

.footer_socials{
    margin-top: 1.6vw;
}

.mailchimp_input{
      width: 51%;
      margin-left: 2vw;
}

.mailchimp_input label{
      width: 100%;
}

#mc-embedded-subscribe{
  font-size: 1.2vw;
}

.footer .back_to_top:hover{
  cursor: pointer;
}

.footer .back_to_top{
  position: absolute;
  bottom: 0;
  right: 2.1vw;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.footer .back_to_top img{
  width: 2vw;
}

.footer .back_to_top img:hover{
  cursor: pointer;
}

.footer .back_to_top div a{
  font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
  font-size: 1.1vw;
  cursor: pointer;
}


@media only screen
    and (max-device-width: 640px),
    only screen and (max-device-width: 667px),
    only screen and (max-width: 480px)
{




  .section_1 .slick-slide img{
    width: 103vw;
  }


  .section_2 .text_container{
    display: block;
  }


  .section_2 .text_container .first_col{
      width: 100%;
  }

  .section_2 .text_container .first_col img{
    width: 94vw;
    height: 100% !important;
  }

  .section_2 .text_container .second_col{
    width: 100%;
    margin-top: 3vw;
    position: relative;
    margin-top: 77vw;
  }

  .section_2 .text_container .second_col p{
    font-size: 6.4vw;
    margin-top: 3vw;
    line-height: 134%;
  }

  marquee{
    font-size: 8vw;
    font-family: arial;
    font-weight: bolder;
    display: none;
  }


  .section_3 .third_grid_container .third_col p{
    font-size: 4vw;
    font-weight: bolder;
    line-height: 160%;
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .section_3 header {
    height: 4vh;
  }

  .section_3 .text_container{
    -ms-flex-direction: column;
        flex-direction: column;
    width: 90vw;
  }

  .section_3 .text_container .first_col{
    width: 100%;
    margin-top: 0;
  }

  .section_3 .text_container .first_col p{
    margin: 0;
    font-size: 4vw;
    margin-bottom: 6vw;
    font-weight: bolder;
    font-family: arial;
    line-height: 180%;

  }

  .section_3 .text_container .second_col{
    width: 100%;
  }

  .section_3 .text_container .second_col img{
    width: 95%;
    height: auto;
  }

  .section_3 .third_grid_container{
    -ms-flex-direction: column;
        flex-direction: column;
        flex-direction: column;
          margin-top: 68vw;
          width: 90vw;
          padding-bottom: 0;
  }

  .section_3 .third_grid_container .first_col{
    width: 100%;
    display: none;
  }

  .section_3 .third_grid_container .second_col{
    margin-top: 0;
    width: 100%;
    display: none;
  }

  .section_3 .third_grid_container .second_col img{
    width: 100%;
  }

  .section_3 .third_grid_container .first_col img{
    width: 100%;
  }

  .section_3 .third_grid_container .mob_only{
    width: 90vw;
    display: -ms-flexbox;
    display: flex;
  }

  .section_3 .third_grid_container .mob_only .first_col{
    display: block;
    margin-top: 3vw;
  }

  .section_3 .third_grid_container .mob_only .second_col{
    display: block;
    margin-left: 5vw;
    margin-top: 10vw;
  }

  .section_3 .third_grid_container .third_col{
    margin-top: 0;
    width: 100%;
    margin-left: 0;
    margin-top: 4vw;
  }

  .section_3 footer{
    margin-top: 8vw;
  }

  .section_4 header{
    height: 10vh;
  }

  .section_4 .third_grid_container{
    display: none;
    -ms-flex-direction: column;
        flex-direction: column;
  }

  .section_4 .third_grid_container_mobile{
    display: block;
    position: relative;
    width: 100vw;
  }


  #accordion{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-direction: column;
        flex-direction: column;
  }

  .third_grid_container_mobile .accordion-toggle {
    cursor: pointer;
    -webkit-filter: brightness(79%);
            filter: brightness(79%);


  }
  .third_grid_container_mobile .accordion-content {
    display: none;

  }



.third_grid_container_mobile .accordion-toggle{
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      width: 80vw;
      height: auto;
      cursor: pointer;
      background-size:  cover;
      background-repeat:  no-repeat;
      background-position: center center;
      width: 65vw;
}


  .third_grid_container_mobile .accordion-toggle div p{
      font-size: 4.3vw;
      font-weight: bolder;
      font-family: arial;
      color: white;
      width: 30vw;
      text-align: center;
  }


.third_grid_container_mobile .accordion_first{
  background-image: url('http://res.cloudinary.com/dhs8w3df1/image/upload/v1511290677/08-mezze-photo_ooytop.png');
}

.third_grid_container_mobile .accordion_second{
  background-image: url('http://res.cloudinary.com/dhs8w3df1/image/upload/v1511290679/08-classic-photo_iww94a.png');
}

.third_grid_container_mobile .accordion_third{
  background-image: url('http://res.cloudinary.com/dhs8w3df1/image/upload/v1511290677/08-fire-photo_ybn0w4.png');
}


  .third_grid_container_mobile .accordion-content p{
    font-size: 4.3vw;
    margin-bottom: 6vw;
    font-weight: bolder;
    font-family: arial;
    line-height: 147%;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 0;
  }


  .section_4 .third_grid_container .first_col,
  .section_4 .third_grid_container .second_col,
  .section_4 .third_grid_container .third_col{
    width: 82%;
    margin-bottom: 0vw;
    -ms-flex-direction: column;
        flex-direction: column;
  }

  .section_4 .third_grid_container  a:hover + .section_4_mob,
  .section_4 .third_grid_container  a:visited + .section_4_mob{
    display: block;
  }

  .section_4 .third_grid_container .section_4_mob{
    width: 90vw;
  }

  .section_4 .third_grid_container .section_4_mob p{
    font-size: 4.3vw;
    margin-bottom: 6vw;
    font-weight: bolder;
    font-family: arial;
    line-height: 150%;
  }



  .section_4 footer{
      width: 90vw;
      display: none;
  }

  .section_4 footer p{
    font-size: 4.3vw;
    margin-bottom: 6vw;
    font-weight: bolder;
    font-family: arial;
  }

  .section_4_desktop{
    display: none;
  }

  .section_4_mobile{
    display: block;
  }


  .section_5 header {
    height: 4vh;
  }


  .section_5 .second .first_col{
    margin-top: 2vw;
  }

  .section_5 .text_container{
    -ms-flex-direction: column;
        flex-direction: column;
    width: 90vw;
  }

  .section_5 .text_container .first_col{
      width: 100%;
  }

  .section_5 .text_container .second_col h1{
    font-family: "Times New Roman", Times, serif;
    font-weight: lighter;
    font-size: 7.1vw;
    margin-top: 1vw;
    margin-bottom: 2.4vw;
  }

    .section_5 .text_container .first_col p{
      font-size: 4.3vw;
      margin-bottom: 5vw;
      font-weight: bolder;
      font-family: arial;
    }

    .section_5 .text_container .second_col{
      width: 100%;
    }

    .section_5 .text_container .second_col img{
      width: 100%;
    }

    .section_5 .text_container:last-child .first_col img{
      width: 100%;
      margin-bottom: 0;
    }

    .section_5 .text_container:last-child .second_col p{
      font-size: 4.3vw;
      margin-bottom: 6vw;
      font-weight: bolder;
      font-family: arial;
      padding-right: 5vw;;
    }


    .section5_mobile{
      display: block
    }


    .section_5 .slider{
      display: none;
    }

    .footer .text_container{
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: left;
          align-items: left;
    }

    .footer .text_container .first_col{
      width: 100%;
    }

    .footer .text_container .first_col .first_col_container{
      width: 100%;
      margin-bottom: 14vw;
    }

    .footer .text_container .second_col{
      width: 100%;
    }

    .footer .text_container .first_col .first_col_container .footer_title{
      margin-bottom: 6vw;
    }

    .footer .text_container .first_col .first_col_container .footer_socials{
      margin-top: 4vw;
    }

    .footer .text_container p{
      font-size: 4vw;
      font-weight: bolder;
      font-family: arial;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;

    }

    .footer .text_container a{
      font-size: 3.3vw;
      font-weight: bolder;
      font-family: arial;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    }


    .footer .text_container .first_col .first_col_container .footer_flex a{
          margin-left: 6vw;
    }

    .footer .text_container .second_col .second_col_container .footer_mailchimp .mailchimp_col_container .flex{
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: left;
          align-items: left;
    }

    .mailchimp_title{
          width: 100% !important;
          margin-bottom: 2vw;
    }

    .mailchimp_input{
      width: 100%;
      margin-left: 0;
    }

    .footer .text_container .second_col .second_col_container .footer_title{
        margin-bottom: 6vw;
    }

     .mailchimp_second_col{
        margin-top: 6vw;
     }

     .mailchimp_col_container{
       margin-bottom: 3vw;
     }

     #mc-embedded-subscribe{
      font-size: 4VW;
      font-weight: bolder;
      font-family: arial;
      background-color: transparent;
      border: none;
      color: black;
     }

    input{
          font-size: 3.3vw;
    }

    .footer .back_to_top{
      width: 100vw;
      bottom: -14vh;
      padding-bottom: 4vw;
      display: none;
    }

    .footer .back_to_top img{
        width: 5vw;
    }

    .footer .back_to_top div a{
          font-size: 4vw;
          font-weight: bolder;

    }

    label input{
      font-size: 3.5vw;
      font-family: arial;
      font-weight: bolder;
      border-radius: 0;
    }


    .section_4 footer .menu_vertical{
      display: none;
    }

    .section_1 .mobile{
      display: block;
    }

    .slider_container{
      display: none;
    }
  
}


/* IPAD PORTRAIT */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {


    .section_1 header img{
      height: 35%;
    }


  body, html{
    overflow-x: hidden;
  }

  marquee{
    white-space: nowrap;
    font-size: 5vw;
  }

  .section_2 .text_container .first_col .slider img {
      height: 35vw;
  }


  .section_3 .text_container .first_col p {
      font-size: 2.1vw;
      line-height: 150%;
      margin: 0;
      margin-right: 4vw;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
  }


  .section_3 .third_grid_container .first_col img{
    width: 27vw;
  }

  .section_3 .third_grid_container .second_col img{
    width: 27vw;
  }


  .section_3 .third_grid_container .third_col p{
    font-size: 2.1vw;
    line-height: 150%;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-weight: bolder;
  }

  .section_3 .third_grid_container .second_col{
    margin-top: 4vw;
  }

  .section_4 .third_grid_container{
    width: 87vw;
  }

  .section_4 footer .menu_vertical p{
    font-size: 3.8vw;
    font-family: "Times New Roman", Times, serif;
    font-weight: lighter;
  }

  .section_4 footer p{
    font-size: 2.1vw;
    line-height: 150%;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-weight: bolder;
  }

  .section_4 footer{
    width: 48vw;
  }

  .section_5 .text_container .first_col p{
    font-size: 2.1vw;
    line-height: 150%;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-weight: bolder;
    padding-right: 3vw;
    padding-bottom: 0;
  }

  .section_5 .text_container .second_col h1{
    margin-top: -13vw;
  }

  .section_5 .text_container:last-child .second_col p{
    font-size: 2.1vw;
    line-height: 150%;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-weight: bolder;
    padding-right: 2vw;
  }

  .footer .text_container p, .footer .text_container a{
    font-size: 1.7vw;
    line-height: 150%;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-weight: bolder;
  }

  .footer .back_to_top div a{
    font-size: 1.7vw;
    line-height: 150%;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-weight: bolder;
  }

  .footer{
    margin-bottom: 6vw;
  }

  .footer .text_container .second_col .second_col_container .footer_mailchimp .mailchimp_col_container .flex div:first-child{
    width: 23%;
  }

  #mc-embedded-subscribe{
    font-size: 1.7vw;
    line-height: 150%;
    font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
    font-weight: bolder;
  }

  textarea,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  .input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
  }

  .section_2 header .menu_container div img:first-child,
  .section_2 header .menu_container div img:last-child{
    width: 20vw;
  }

  #MERGE0{
  border-radius: 0;
  }

  .section_2 header .menu_container div:nth-child(2) img:last-child{
    width: 15vw;
  }

  .section_3{
    margin-top: 2.5vw;
  }

  .section_4 header{
    height: 13vh;
  }
  }



  /* IPAD Landscape */
  @media only screen
  and (min-device-width : 768px)
  and (max-device-width : 1024px)
  and (orientation : landscape) {


      body, #root{
        overflow-x: hidden;
      }


      .section_1 header img{
        height: 55%;
      }

      .section_1 .slick-slide img{
        height: 82vh;
        width: auto;
      }

      .section_2 .heading_container h1{
        font-size: 6vw;
        margin-bottom: 2vw;
      }

      .section_3{
        position: relative;
        margin-top: 9vw;
      }

      .section_4 .third_grid_container{
        width: 78vw;
      }


    body, html{
      overflow-x: hidden;
    }

    marquee{
      white-space: nowrap;
      font-size: 5vw;
    }

    .section_2 .text_container .first_col .slider img {
        height: 35vw;
    }


    .section_3 .text_container .first_col p {
        font-size: 2.1vw;
        line-height: 150%;
        margin: 0;
        margin-right: 4vw;
        font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
        font-weight: bolder;
    }


    .section_3 .third_grid_container .first_col img{
      width: 27vw;
    }

    .section_3 .third_grid_container .second_col img{
      width: 27vw;
    }


    .section_3 .third_grid_container .third_col p{
      font-size: 2.1vw;
      line-height: 150%;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
    }

    .section_3 .third_grid_container .second_col{
      margin-top: 4vw;
    }



    .section_4 footer .menu_vertical p{
      font-size: 3.5vw;
      font-family: "Times New Roman", Times, serif;
      font-weight: lighter;
    }

    .section_4 footer p{
      font-size: 2.1vw;
      line-height: 150%;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
    }

    .section_4 footer{
      width: 48vw;
      margin-bottom: 6vw;
    }

    .section_4 header{
      margin-top: 1vw;
    }

    .section_5 .text_container .first_col p{
      font-size: 2.1vw;
      line-height: 150%;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
      padding-right: 3vw;
      padding-bottom: 0;
    }

    .section_5 .text_container .second_col h1{
      margin-top: -13vw;
    }

    .section_5 .text_container:last-child .second_col p{
      font-size: 2.1vw;
      line-height: 150%;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
      padding-right: 2vw;
    }

    .footer .text_container p, .footer .text_container a{
      font-size: 1.7vw;
      line-height: 150%;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
    }

    .section_1 footer{
      height: 5vh;
    }


    .footer .back_to_top div a{
      font-size: 1.7vw;
      line-height: 150%;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
    }

    .footer{
      margin-bottom: 6vw;
    }

    .footer .text_container .second_col .second_col_container .footer_mailchimp .mailchimp_col_container .flex div:first-child{
      width: 23%;
    }

    #mc-embedded-subscribe{
      font-size: 1.7vw;
      line-height: 150%;
      font-family: "Arial Black", "Arial Bold", Arial, sans-serif;
      font-weight: bolder;
    }

    textarea,
    input.text,
    input[type="text"],
    input[type="button"],
    input[type="submit"],
    .input-checkbox {
    -webkit-appearance: none;
    border-radius: 0;
    }

    .section_2 header .menu_container {
      margin-top: 2vw;
      margin-bottom: 2vw;
    }

    .section_2 header .menu_container div img:first-child,
    .section_2 header .menu_container div img:last-child{
      width: 15vw;
    }

    #MERGE0{
    border-radius: 0;
    }

    .section_2 header .menu_container div:nth-child(2) img:last-child{
      width: 12vw;
    }

    .section_2 .text_container{
      margin-bottom: 2vw;
    }
}

@-webkit-keyframes rotate_3imgs
{
  0%   {z-index:1}
  33.333% { z-index: 0}
  66.666% { z-index: 0}
  100% { z-index: 1}
}

@keyframes rotate_3imgs
{
  0%   {z-index:1}
  33.333% { z-index: 0}
  66.666% { z-index: 0}
  100% { z-index: 1}
}
@-webkit-keyframes rotate_3imgs_2
{
  0%   {z-index:0}
  33.333% { z-index: 1}
  66.666% { z-index: 0 }
  100% { z-index: 0}
}
@keyframes rotate_3imgs_2
{
  0%   {z-index:0}
  33.333% { z-index: 1}
  66.666% { z-index: 0 }
  100% { z-index: 0}
}
@-webkit-keyframes rotate_3imgs_3
{
  0%   {z-index:0}
  33.333% { z-index: 0}
  66.666% { z-index: 1}
  100% { z-index: 0}
}
@keyframes rotate_3imgs_4
{
  0%   {z-index:0}
  33.333% { z-index: 0}
  66.666% { z-index: 0}
  100% { z-index: 1}
}

@-webkit-keyframes rotate_3imgs_4
{
  0%   {z-index:0}
  33.333% { z-index: 0}
  66.666% { z-index: 0}
  100% { z-index: 1}
}
@keyframes rotate_3imgs_4
{
  0%   {z-index:0}
  33.333% { z-index: 0}
  66.666% { z-index: 0}
  100% { z-index: 1}
}



@-webkit-keyframes rotate_4imgs
{
  0%   {z-index:1}
  25% { z-index: 0}
  50% { z-index: 0}
  75% { z-index: 0}
  100% { z-index: 1}
}



@keyframes rotate_4imgs
{
  0%   {z-index:1}
  25% { z-index: 0}
  50% { z-index: 0}
  75% { z-index: 0}
  100% { z-index: 1}
}
@-webkit-keyframes rotate_4imgs_2
{
  0%   {z-index:0}
  25% { z-index: 1}
  50% { z-index: 0}
  75% { z-index: 0}
  100% { z-index: 0}
}
@keyframes rotate_4imgs_2
{
  0%   {z-index:0}
  25% { z-index: 1}
  50% { z-index: 0}
  75% { z-index: 0}
  100% { z-index: 0}
}
@-webkit-keyframes rotate_4imgs_3
{
  0%   {z-index:0}
  25% { z-index: 0}
  50% { z-index: 1}
  75% { z-index: 0}
  100% { z-index: 0}
}
@keyframes rotate_4imgs_3
{
  0%   {z-index:0}
  25% { z-index: 0}
  50% { z-index: 1}
  75% { z-index: 0}
  100% { z-index: 0}
}
@-webkit-keyframes rotate_4imgs_4
{
  0%   {z-index:0}
  25% { z-index: }
  50% { z-index: 0}
  75% { z-index: 1}
  100% { z-index: 0}
}
@keyframes rotate_4imgs_4
{
  0%   {z-index:0}
  25% { z-index: }
  50% { z-index: 0}
  75% { z-index: 1}
  100% { z-index: 0}
}
